/* This stylesheet generated by Transfonter (http://transfonter.org) on June 15, 2016 2:42 PM */

@font-face {
	font-family: 'VAG Rounded Std';
	src: url('hinted-VAGRoundedStd-Light.eot');
	src: local('VAG Rounded Std Light'), local('VAGRoundedStd-Light'),
		url('hinted-VAGRoundedStd-Light.eot?#iefix') format('embedded-opentype'),
		url('hinted-VAGRoundedStd-Light.woff2') format('woff2'),
		url('hinted-VAGRoundedStd-Light.woff') format('woff'),
		url('hinted-VAGRoundedStd-Light.ttf') format('truetype'),
		url('hinted-VAGRoundedStd-Light.svg#VAGRoundedStd-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'VAG Rounded Std';
	src: url('hinted-VAGRoundedStd-Thin.eot');
	src: local('VAG Rounded Std Thin'), local('VAGRoundedStd-Thin'),
		url('hinted-VAGRoundedStd-Thin.eot?#iefix') format('embedded-opentype'),
		url('hinted-VAGRoundedStd-Thin.woff2') format('woff2'),
		url('hinted-VAGRoundedStd-Thin.woff') format('woff'),
		url('hinted-VAGRoundedStd-Thin.ttf') format('truetype'),
		url('hinted-VAGRoundedStd-Thin.svg#VAGRoundedStd-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'VAG Rounded Std';
	src: url('hinted-VAGRoundedStd-Black.eot');
	src: local('VAG Rounded Std Black'), local('VAGRoundedStd-Black'),
		url('hinted-VAGRoundedStd-Black.eot?#iefix') format('embedded-opentype'),
		url('hinted-VAGRoundedStd-Black.woff2') format('woff2'),
		url('hinted-VAGRoundedStd-Black.woff') format('woff'),
		url('hinted-VAGRoundedStd-Black.ttf') format('truetype'),
		url('hinted-VAGRoundedStd-Black.svg#VAGRoundedStd-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'VAG Rounded Std';
	src: url('hinted-VAGRoundedStd-Bold.eot');
	src: local('VAG Rounded Std Bold'), local('VAGRoundedStd-Bold'),
		url('hinted-VAGRoundedStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('hinted-VAGRoundedStd-Bold.woff2') format('woff2'),
		url('hinted-VAGRoundedStd-Bold.woff') format('woff'),
		url('hinted-VAGRoundedStd-Bold.ttf') format('truetype'),
		url('hinted-VAGRoundedStd-Bold.svg#VAGRoundedStd-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
